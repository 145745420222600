import { template as template_92499909a2a9490a9bf4b50ee3db87b8 } from "@ember/template-compiler";
const FKLabel = template_92499909a2a9490a9bf4b50ee3db87b8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
