import { template as template_1cf7e58501514bc79de0cb5a9a1fff9f } from "@ember/template-compiler";
const SidebarSectionMessage = template_1cf7e58501514bc79de0cb5a9a1fff9f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
